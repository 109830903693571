import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";

import Layout from "../components/layout";

const AllPage = ({ data }) => {
  const sortedData = [
    ...data.stories.nodes,
    ...data.words.nodes,
    ...data.theories.nodes,
    ...data.videos.nodes,
    ...data.sketches.nodes,
  ].sort((a, b) => b.date.localeCompare(a.date));

  const color = (type) => {
    switch (type) {
      case "story":
        return "#ff8a00";
      case "word":
        return "#dbff00";
      case "theory":
        return "#7b68ee";
      case "video":
        return "#86f000";
      case "sketch":
        return "#c71585";
      default:
        return "white";
    }
  };

  const link = (d) => {
    switch (d._type) {
      case "story":
        return `/stories/${d.slug.current}`;
      case "word":
        return `/words#${d.id}`;
      case "theory":
        return `/theories/${d.slug.current}`;
      case "video":
        return `/videos/${d.slug.current}`;
      case "sketch":
        return `/sketches/${d.slug.current}`;
      default:
        return `/`;
    }
  };

  return (
    <Layout title="skeleton key">
      {sortedData.map((d) => (
        <div
          key={d.id}
          css={css`
            margin-bottom: 20px;
          `}
        >
          <Link to={link(d)}>
            <p
              css={css`
                display: inline;
                margin-right: 20px;
              `}
            >
              {d.date}
            </p>
            <h3
              css={css`
                display: inline;
              `}
            >
              <span
                css={css`
                  color: ${color(d._type)};
                `}
              >
                {d._type}
              </span>
              : {d.title || d.content}
            </h3>
          </Link>
        </div>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query AllQuery {
    stories: allSanityStory {
      nodes {
        slug {
          current
        }
        title
        date
        _type
        id
      }
    }
    words: allSanityWord {
      nodes {
        content
        date
        _type
        id
      }
    }
    theories: allSanityTheory {
      nodes {
        slug {
          current
        }
        title
        date
        _type
        id
      }
    }
    videos: allSanityVideo {
      nodes {
        slug {
          current
        }
        title
        date
        _type
        id
      }
    }
    sketches: allSanitySketch {
      nodes {
        slug {
          current
        }
        title
        date
        _type
        id
      }
    }
  }
`;

export default AllPage;
